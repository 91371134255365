import React from 'react'

import styles from './EligibleCombos.module.css'

type Tprops = {
  id: string
  eligibleCombos: any
  finalScoreColour: string
}

function EligibleCombos(props: Tprops) {
  const renderComboCriteria = (criteria: any) => {
    const renderCriteriaDetails = () => {
      if (criteria.fieldType === 'text') {
        return criteria.textValues.join(', ')
      } else if (criteria.fieldType === 'range') {
        if (criteria.rangeType === 'inBetween') {
          return `${criteria.rangeType} ${criteria.rangeValue.min} and ${criteria.rangeValue.max}`
        }

        return `${criteria.rangeType} ${
          criteria.rangeValue.min || criteria.rangeValue.max
        }`
      }
    }

    return (
      <p key={criteria.fieldName}>
        <strong>{criteria.fieldName}</strong> {renderCriteriaDetails()}
      </p>
    )
  }

  const renderComboEligibilities = (eligibilities: any) => {
    return Object.keys(eligibilities).map((key) => {
      const eligibility = eligibilities[key]

      if (eligibility.type === 'freebieFlag') {
        return (
          <p key={key}>
            <strong>Free</strong> {key}
          </p>
        )
      }

      return (
        <p key={key}>
          <strong>{key}</strong> {eligibility.type}{' '}
          <strong>{eligibility.value}</strong>
        </p>
      )
    })
  }

  const renderCombo = (combo: any) => {
    return (
      <div key={combo.SK} className={styles.combo_container}>
        <h2>{combo.name}</h2>
        <div className={styles.product_content}>
          <p className={styles.attribute_title}>Products</p>
          <div className={styles.attribute_body}>
            {combo.products?.map((product: any) => (
              <React.Fragment key={product.sku}>
                <strong>{product.sku}</strong> {product.name}
                <br />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className={styles.combo_content}>
          <div className={styles.attribute_container}>
            <p className={styles.attribute_title}>Criteria</p>
            <div className={styles.attribute_body}>
              {combo.criteria?.map((criteria: any) =>
                renderComboCriteria(criteria)
              )}
            </div>
          </div>
          <div className={styles.attribute_container}>
            <p className={styles.attribute_title}>Eligibilities</p>
            <div className={styles.attribute_body}>
              {renderComboEligibilities(combo.eligibilities)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderEligibleCombos = () => {
    if (props.eligibleCombos?.data?.eligibleCombos?.length > 0) {
      return (
        <React.Fragment>
          <div>
            {props.eligibleCombos?.data?.eligibleCombos.map((combo: any) =>
              renderCombo(combo)
            )}
          </div>
        </React.Fragment>
      )
    }

    return null
  }

  const renderContent = () => {
    switch (props.eligibleCombos?.loading) {
      case 'completed':
        if (props.eligibleCombos?.data) {
          return (
            <React.Fragment>
              <div className={styles.final_score}>
                Final Score
                <div className="score_wrapper">
                  <h1>{props.eligibleCombos?.data?.finalScore}</h1>
                  {props.finalScoreColour && (
                    <div
                      className="score_color"
                      style={{ background: props.finalScoreColour }}
                    />
                  )}
                </div>
              </div>
              <p>
                <strong>
                  {props.eligibleCombos?.data?.eligibleCombos?.length || 0}
                </strong>
                potential eligible combo(s) found.
              </p>
              {renderEligibleCombos()}
            </React.Fragment>
          )
        }

        return null
      case 'failed':
        return <p>No eligible combo found.</p>
      case 'pending':
        return <p>Loading eligible combo...</p>
      default:
        return null
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.title}>
          <h2>Eligible Combo</h2>
        </div>
        <div className={styles.body}>{renderContent()}</div>
      </div>
    </div>
  )
}

export default EligibleCombos
